import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";

const FAQs = () => {
  const [activeLeftIndex, setActiveLeftIndex] = useState<number | null>(null);
  const [activeRightIndex, setActiveRightIndex] = useState<number | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [categoryTitle, setCategoryTitle] = useState<string | null>(null); // New state to track category title
  const [searchQuery, setSearchQuery] = useState<string>("");

  const toggleLeftAnswer = (index: number) => {
    setActiveLeftIndex(activeLeftIndex === index ? null : index);
  };

  const toggleRightAnswer = (index: number) => {
    setActiveRightIndex(activeRightIndex === index ? null : index);
  };

  const faqs = [
    {
      category: "Delivery related",
      question:
        "What should I do if my order is approved but hasn't been shipped yet?",
      answer:
        "If your order is approved but hasn't been shipped yet, please contact our support team for assistance.",
    },
    {
      category: "Delivery related",
      question: "Is Open box delivery available for all products?",
      answer:
        "Open box delivery is available for select products only. Check the product page for details.",
    },
    {
      category: "Login and my account",
      question:
        "Do I need to verify my mobile number or email address every time I log in?",
      answer:
        "No, once verified, you don’t need to verify it again unless there's a security concern.",
    },
    {
      category: "Login and my account",
      question: "Can I update my account information?",
      answer:
        "Yes, you can update your account information by going to 'My Profile' section after logging in.",
    },
    {
      category: "Payment",
      question:
        "How do I get invoices for my previous order without having to check my emails?",
      answer:
        "You can access invoices from your account order history section.",
    },
    {
      category: "Payment",
      question:
        "What payment methods do you accept, and is there a preferred option for customers?",
      answer:
        "This question can be used to inquire about the types of payment options available (e.g., credit/debit cards, PayPal, bank transfers, etc.) and whether there are any preferred or recommended methods for customers.",
    },
    {
      category: "Open box delivery",
      question: "How do I return an open box item?",
      answer: "Contact customer support for return instructions.",
    },
    {
      category: "Open box delivery",
      question:
        "Do you offer open box delivery, and if so, what are the conditions for receiving products in an open box?",
      answer:
        "Yes, we offer open box delivery for certain items. This means the product is shipped without its original packaging but is fully inspected and functional. Open box items are typically available at a lower price.",
    },

    {
      category: "Cayro EMI",
      question: "Can I pay my EMI using a credit card?",
      answer: "Yes, you can pay through credit card for EMI.",
    },
    {
      category: "Cayro EMI",
      question:
        "Do you offer EMI (Equated Monthly Installment) options, and how does it work?",
      answer:
        "Yes, we offer EMI options on select products. You can pay for your purchase in monthly installments, with the duration and interest rates depending on the payment plan you choose. Simply select EMI at checkout, and you’ll be guided through the available plans.",
    },
    {
      category: "Others",
      question: "Do you offer cash on delivery (COD)?",
      answer:
        "Yes, we offer cash on delivery for certain products. You can select COD as your payment method during checkout and pay in cash when your order is delivered.y",
    },
    {
      category: "Others",
      question: "Can I cancel my order after it's shipped?",
      answer:
        "You can cancel your order if it hasn't been shipped yet. Once shipped, cancellation may not be possible.",
    },
  ];

  // Filter FAQs based on selected category
  const filteredFAQs = selectedCategory
    ? faqs.filter((faq) => faq.category === selectedCategory)
    : faqs;

  // Split the filteredFAQs array into two parts (left and right sections)
  const midIndex = Math.floor(filteredFAQs.length / 2);
  const leftFAQs = filteredFAQs.slice(0, midIndex); // Left section
  const rightFAQs = filteredFAQs.slice(midIndex); // Right section

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const highlightSearchQuery = (text: string) => {
    if (!searchQuery) return text;

    const regex = new RegExp(`(${searchQuery})`, "gi");
    return text.split(regex).map((part, index) =>
      regex.test(part) ? (
        <span key={index} style={{ backgroundColor: "yellow" }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div className="container mx-auto px-4 py-8 mt-[100px]">
        <div className="flex w-full justify-between p-[20px] sm:flex-col-reverse">
          <div className="mb-8 flex flex-col">
            <h1 className="text-4xl font-semibold text-gray-800">
              Welcome to Cayro Customer Service
            </h1>
            <h2 className="mt-2 text-lg text-gray-600">
              We're here to assist you with any questions or concerns—how can we
              help you today?
            </h2>
          </div>
          <div className="flex flex-col md:flex-row items-center justify-between mb-6">
            <div className="flex flex-col mb-4 md:mb-0 md:w-2/3">
              <h3 className="text-[15px] font-medium text-gray-700">
                Search our help library
              </h3>
              <input
                type="text"
                placeholder="Search..."
                className="mt-2 px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
          </div>
        </div>

        <div className="faq-list mt-8 grid gap-8 lg:gap-12">
          {/* Row 1 */}
          <div className="cards flex justify-center gap-6 sm:flex-wrap sm:flex-col">
            <div
              className="card flex items-center gap-4 p-4 w-full border border-yellow-500 rounded-lg hover:shadow-lg transition-shadow"
              onClick={() => {
                setSelectedCategory("Delivery related");
                setCategoryTitle("Delivery related"); // Set category title
              }}
            >
              <img
                src="/faqs/scooter.png"
                alt="Delivery Icon"
                className="w-12 h-12"
              />
              <h1 className="text-lg font-semibold ">Delivery related</h1>
            </div>
            <div
              className="card flex items-center gap-4 p-4 w-full border border-emerald-500 rounded-lg hover:shadow-lg transition-shadow"
              onClick={() => {
                setSelectedCategory("Login and my account");
                setCategoryTitle("Login and my account"); // Set category title
              }}
            >
              <img
                src="/faqs/login.png"
                alt="Login Icon"
                className="w-12 h-12"
              />
              <h1 className="text-lg font-semibold">Login and my account</h1>
            </div>
            <div
              className="card flex items-center gap-4 p-4 w-full border border-indigo-500 rounded-lg hover:shadow-lg transition-shadow"
              onClick={() => {
                setSelectedCategory("Payment");
                setCategoryTitle("Payment"); // Set category title
              }}
            >
              <img
                src="/faqs/payment.png"
                alt="Payment Icon"
                className="w-12 h-12"
              />
              <h1 className="text-lg font-semibold">Payment</h1>
            </div>
          </div>

          {/* Row 2 */}
          <div className="cards flex justify-center gap-6 sm:flex-wrap sm:flex-col">
            <div
              className="card flex items-center gap-4 p-4 w-full border border-sky-600 rounded-lg hover:shadow-lg transition-shadow"
              onClick={() => {
                setSelectedCategory("Open box delivery");
                setCategoryTitle("Open box delivery"); // Set category title
              }}
            >
              <img
                src="/faqs/openbox.png"
                alt="Open Box Icon"
                className="w-12 h-12"
              />
              <h1 className="text-lg font-semibold">Open box delivery</h1>
            </div>
            <div
              className="card flex items-center gap-4 p-4 w-full border border-rose-600 rounded-lg hover:shadow-lg transition-shadow"
              onClick={() => {
                setSelectedCategory("Cayro EMI");
                setCategoryTitle("Cayro EMI"); // Set category title
              }}
            >
              <img src="/faqs/emi.png" alt="EMI Icon" className="w-12 h-12" />
              <h1 className="text-lg font-semibold">Cayro EMI</h1>
            </div>
            <div
              className="card flex items-center gap-4 p-4 w-full border border-amber-200 rounded-lg hover:shadow-lg transition-shadow"
              onClick={() => {
                setSelectedCategory("Others");
                setCategoryTitle("Others"); // Set category title
              }}
            >
              <img
                src="/faqs/others.png"
                alt="Others Icon"
                className="w-12 h-12"
              />
              <h1 className="text-lg font-semibold">Others</h1>
            </div>
          </div>
        </div>

        {/* Show the related questions title */}
        {categoryTitle && (
          <h2 className="mt-12 text-2xl font-semibold text-center">
            {categoryTitle}
          </h2>
        )}

        <div className="flex gap-4 mt-8 w-full justify-between sm:flex-col">
          {/* Left FAQs Section */}
          <div className="left flex flex-col space-y-4 w-[50%] sm:w-full sm:mt-[50px]">
            {leftFAQs.map((faq, index) => (
              <div
                key={index}
                className="flex gap-[20px] border rounded-lg shadow-md p-4 bg-white hover:shadow-lg transition-shadow w-[70%] m-auto"
              >
                <div
                  className="w-full cursor-pointer"
                  onClick={() => toggleLeftAnswer(index)}
                >
                  <div className="flex justify-between">
                    <p className="font-semibold text-lg">
                      {highlightSearchQuery(faq.question)}
                    </p>
                    <div>
                      {activeLeftIndex === index ? (
                        <AiOutlineMinus />
                      ) : (
                        <AiOutlinePlus />
                      )}
                    </div>
                  </div>
                  {activeLeftIndex === index && (
                    <div className="mt-2">{faq.answer}</div>
                  )}
                </div>
              </div>
            ))}
          </div>

          {/* Right FAQs Section */}
          <div className="right flex flex-col space-y-4 w-[50%] sm:w-full sm:mt-[50px]">
            {rightFAQs.map((faq, index) => (
              <div
                key={index}
                className="flex gap-[20px] border rounded-lg shadow-md p-4 bg-white hover:shadow-lg transition-shadow w-[70%] m-auto"
              >
                <div
                  className="w-full cursor-pointer"
                  onClick={() => toggleRightAnswer(index)}
                >
                  <div className="flex justify-between">
                    <p className="font-semibold text-lg">
                      {highlightSearchQuery(faq.question)}
                    </p>
                    <div>
                      {activeRightIndex === index ? (
                        <AiOutlineMinus />
                      ) : (
                        <AiOutlinePlus />
                      )}
                    </div>
                  </div>
                  {activeRightIndex === index && (
                    <div className="mt-2">{faq.answer}</div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQs;
