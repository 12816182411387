const BottomDesc = ({ metaInfo }: any) => {
    const hasMetaInfo = metaInfo?.metaInfoTitle && metaInfo?.metaInfoDescription;

    return (
        hasMetaInfo && (
            <div className="bg-gray-100 p-6 ">
                {/* Meta title section */}
                <h2 className="text-lg font-semibold text-gray-800 mb-2">
                    {metaInfo?.metaInfoTitle}
                </h2>

                {/* Meta description section */}
                <p className="text-sm text-gray-600">
                    {metaInfo?.metaInfoDescription}
                </p>
            </div>
        )
    );
};

export default BottomDesc;
