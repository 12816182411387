import { Link, useNavigate } from "react-router-dom";

import { useEffect, useRef, useState } from "react";
import { MdKeyboardArrowRight } from "react-icons/md";

const Sidebar = ({
  setShowSideBarInMobile,
  allCategory,
}: any) => {
  const showSideBarInMobileRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [selectedIndexOfCategory, setSelectedIndexOfCategory] = useState<number | null>(null); 



  const handleClickOutside = (event: MouseEvent) => {
    if (
      showSideBarInMobileRef.current &&
      !showSideBarInMobileRef.current.contains(event.target as Node)
    ) {
      setShowSideBarInMobile(false);
    }
  };

  const handleLinkClick = () => {
    setShowSideBarInMobile(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const handleCategoryClick = (index: number, subCategories: any[],  category: any) => {
    if (subCategories.length === 0) {
      setShowSideBarInMobile(false);
      navigate(`/product-list?category=${category.category}&category_id=${category._id}&page=1`);
    } else if (subCategories.length === 1) {
      setShowSideBarInMobile(false);
      navigate(`/product-list?subcategory=${subCategories[0].name}&sub-category-id=${subCategories[0]._id}&page=1`);
    } else {
      setSelectedIndexOfCategory(selectedIndexOfCategory === index ? null : index);
    }
  };
  

  

  return (
    <div
      ref={showSideBarInMobileRef}
      id="drawer-body-scrolling"
      className="absolute w-full delay-300 overflow-y-scroll no-scrollbar left-[0px] top-[0px] z-40 h-screen p-4 overflow-y-auto bg-white max-w-[240px]"
      style={{
        boxShadow:
          "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
      }}
      aria-labelledby="drawer-body-scrolling-label"
    >
      {/* <h5
        id="drawer-body-scrolling-label"
        className="text-sm font-bold text-black capitalize flex mb-2"
      >
        <SlUser className="h-5 w-5" /> &nbsp;&nbsp; Hello,{" "}
        {userDetail.name ? (
          userDetail.name
        ) : (
          <Link to="/register" onClick={() => setShowSideBarInMobile(false)}>
            {" "}
            Sign In
          </Link>
        )}
      </h5> */}
      {/* <hr /> */}
      <button
        type="button"
        data-drawer-hide="drawer-body-scrolling"
        aria-controls="drawer-body-scrolling"
        onClick={() => setShowSideBarInMobile(false)}
        className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 end-2.5 inline-flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white"
      >
        <svg
          className="w-3 h-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 14 14"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
          />
        </svg>
        <span className="sr-only text-black font-bold">Close menu</span>
      </button>

      <div className="pb-4 overflow-y-auto">
        <ul className="space-y-2 font-medium no-scrollbar">
          <h2 className="block text-black text-sm font-semibold">
            All Categories
          </h2>
          <hr />
          {allCategory.map((category: any,index:number) => {
            return (
              <li className="text-[#1d2128] text-[0.85rem] p-1"
              key={category._id} // Add key prop here for the category

              onClick={() => handleCategoryClick(index, category.subCategories, category)}              >
                  <span className="flex-1  whitespace-nowrap " >
                    {category.category}{" "} 
                    <MdKeyboardArrowRight className="inline" />
                  </span>
                {/* </Link> */}

                {/* subcategory */}

                {selectedIndexOfCategory === index && category.subCategories.length > 0 && (
                  <ul className="ml-4">
                    {category.subCategories.map((subcat: any) => (
                      <li
                        className="text-md my-2 cursor-pointer text-black hover:text-primary"
                        key={subcat._id}
                      >
                        <Link
                          onClick={handleLinkClick} // Close sidebar on subcategory click
                          to={`product-list?subcategory=${subcat.name}&sub-category-id=${subcat._id}&page=1`}
                        >
                          {subcat.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            );
          })}
         
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
