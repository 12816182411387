import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaShoppingCart } from "react-icons/fa";
import Loader from "../../common/loader/Loader";
import {
  // addToCart,
  // decrementQuantity,
  fetchFiltersForCategory,
  fetchFiltersForSubCategory,
  fetchFilterValueForbrand,
  filters,
  getAllProductAccordingToCategoryOrSubCategoryOrBrand,
  // incrementQuantity,
  isLoadingForProduct,
  metaInfo,
  setProduct,
  totalItemForProduct,
  totalPageForProduct,
  updateSelectedFilter,
} from "../../slice/ProductSlice";
import { AppDispatch } from "../../store/store";
import { product } from "../../slice/ProductSlice";
import Pagination from "../../pagination/Pagination";
import { RxCross2 } from "react-icons/rx";
import { useMediaQuery } from "@react-hook/media-query";
import {
  addProductForComparison,
  compareProducts,
  removeProductForComparison,
  setSubCategoryIdForComparison,
  subCategoryIdForComparison,
} from "../../slice/CompareProductsSlice";
import {
  showErrorToast,
  showInfoToast,
  showSuccessToast,
} from "../../utils/toast";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { addProduct, setCartLength } from "../../slice/CartSlice";
import { PostRequest } from "../../api/Request";
import { RenderHelmetForCat } from "../../utils/Helmet";
import UpperBanner from "./UpperBanner";
import BottomDesc from "./BottomDesc";

const ProductListing = () => {
  const metaInfoForProductListing = useSelector(metaInfo);

  const subCategoryIdForCompare = useSelector(subCategoryIdForComparison);
  const compareTheseProducts = useSelector(compareProducts);
  const isMobile = useMediaQuery("(max-width: 780px)");

  const totalPages = useSelector(totalPageForProduct);
  const totalItems = useSelector(totalItemForProduct);
  const navigate = useNavigate();
  const productFilter = useSelector(filters);
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const query: any = new URLSearchParams(location.search);
  const [pageForProduct, setPageForProduct] = useState<number>(
    query.get("page")
  );
  const [searchParams] = useSearchParams()
  const category = searchParams.get('category');
  const subcategory=searchParams.get('subcategory')
  const brand=searchParams.get('brandName')

  // New state to track loading for each product
  const [loadingProducts, setLoadingProducts] = useState<{
    [key: string]: boolean;
  }>({});
  const allProduct = useSelector(product);

  const [openCategory, setOpenCategory] = useState<any>([]);
  const isLoading = useSelector(isLoadingForProduct);
  const [maxPrice, setMaxPrice] = useState<any>(query.get("maxPrice"));
  const [showFilterOnMobile, setShowFilterOnMobile] = useState<boolean>(false);
  const [minPrice, setMinPrice] = useState<any>(query.get("minPrice"));

  const [sortBy, setSortBy] = useState("");

  // Debounce function to delay API calls
  const useDebounce = (value: any, delay: any) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);

    return debouncedValue;
  };

  // Use the debounced value for max price
  const debouncedMaxPrice = useDebounce(maxPrice, 500); // 300ms delay

  // Handle slider change
  const handleMaxChange = (e: any) => {
    const value = Number(e.target.value);
    setMaxPrice(value);
  };

  // Effect to handle API call when debounced value changes
  useEffect(() => {
    if (debouncedMaxPrice) {
      const searchParams = new URLSearchParams(location.search);

      searchParams.set("maxPrice", debouncedMaxPrice); // Update maxPrice
      searchParams.set("minPrice", productFilter?.minPrice); // Ensure minPrice is set

      navigate(`?${searchParams.toString()}`);
    }
  }, [debouncedMaxPrice, navigate, minPrice, location.search]);
  const handleSortChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSortBy(event.target.value); // Update the sortBy state when the user selects a new option
  };

  useEffect(() => {
    const subCategoryId: any = query.get("sub-category-id");
    const categoryId: any = query.get("category_id");
    const brandId: any = query.get('brand-id')
    const searchParams = new URLSearchParams(location.search);
    const filters: { [key: string]: any } = {};

    // Extract minPrice and maxPrice from the query parameters
    const minPrice = searchParams.get("minPrice");
    const maxPrice = searchParams.get("maxPrice");
    const page = searchParams.get("page");

    // Loop through all the keys in the search parameters
    searchParams.forEach((value, key) => {
      if (
        ["category_id", "sub-category-id", "page", "category", "minPrice", "maxPrice"].includes(
          key.toLowerCase()
        )
      ) {
        return; // Skip this iteration if the key matches any of the excluded keys
      }

      // Check if the value is a single value or multiple values
      if (searchParams.getAll(key).length > 1) {
        filters[key] = searchParams.getAll(key); // If multiple values exist for this key
      } else {
        filters[key] = value; // Single value
      }
    });


    // Dispatch the action to fetch products
    dispatch(
      getAllProductAccordingToCategoryOrSubCategoryOrBrand({
        subCategoryIdForProduct: subCategoryId,
        categoryIdForProduct: categoryId,
        ...filters,
        maxPrice: maxPrice || undefined, // Ensure these are undefined if not present
        minPrice: minPrice || undefined,
        sortBy,
        pageForProduct: page,
        brandId
      })
    );

    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, [location.search, dispatch, sortBy]);

  useEffect(() => {
    const subCategoryId: any = query.get("sub-category-id");
    const categoryId: any = query.get("category_id");
    const brandId: any = query.get("brand-id");
    // Dispatch the action to fetch filters
    if (categoryId) {
      dispatch(fetchFiltersForCategory(categoryId));
    }
    if (subCategoryId) {
      dispatch(fetchFiltersForSubCategory(subCategoryId));
    }
    if (brandId) {
      dispatch(fetchFilterValueForbrand(brandId));
    }
  }, [
    query.get("sub-category-id"),
    query.get("category_id"),
    query.get("brand-id"),
  ]);

  const toggleDropdown = (key: string) => {
    let newCategory = [...openCategory];
    if (newCategory.includes(key)) {
      newCategory = newCategory.filter((category: any) => {
        return category !== key;
      });
    } else {
      newCategory = newCategory.concat(key);
    }

    return setOpenCategory(() => newCategory);
  };

  const handleCheckboxChange = (filterField: string, filterValue: any) => {
    dispatch(updateSelectedFilter({ filterField, filterValue }));

    const searchParams = new URLSearchParams(location.search);

    // Function to handle the appending/removal of filter values dynamically
    const updateSearchParams = (key: string, value: any) => {
      let existingValues: string[];
      let lowercasedValue = value;

      // Handle the "Brands" field with lowercase conversion
      if (key === "Brands") {
        existingValues = searchParams.getAll("brand");
        key = "brand"; // Replace "Brands" with "brand"
        lowercasedValue = value.toLowerCase(); // Convert value to lowercase only for "brand"
      } else {
        existingValues = searchParams.getAll(key);
      }

      // Add or remove the filter value from search parameters
      if (!existingValues.includes(lowercasedValue)) {
        searchParams.append(key, lowercasedValue); // Append if not included
      } else {
        const updatedValues = existingValues.filter(
          (item) => item !== lowercasedValue
        );
        searchParams.delete(key); // Clear existing values
        updatedValues.forEach((item) => searchParams.append(key, item)); // Re-add remaining values
      }
    };

    // Dynamically update the search parameters based on the filter field and value
    updateSearchParams(filterField, filterValue);

    // Navigate to the updated URL with the new search parameters
    navigate(`?${searchParams.toString()}`);
  };

  const isCheckedOrNot = (key: string, item: any) => {
    const searchParams = new URLSearchParams(location.search);

    // Normalize the key if it follows a lowercase naming convention (you can skip this for certain keys if needed)
    const normalizedKey = key === "Brands" ? "brand" : key;

    // Get all the query parameter values for the key
    const existingValues = searchParams.getAll(normalizedKey);

    return existingValues.includes(item);
  };


  // Updated handleAddToCart function
  const handleAddToCart = async (product: any, index: number) => {
    setLoadingProducts((prev) => ({ ...prev, [product._id]: true }));
    const token = localStorage.getItem("userToken");


    if (!token) {
      // Handle add to cart locally for non-logged-in user
      dispatch(
        setProduct({
          product: { ...product, addToCartStatus: "Update Cart" },
          index,
        })
      );
      dispatch(
        addProduct({ ...product, quantityByUser: product.quantityByUser })
      );
      dispatch(
        setCartLength(JSON.parse(localStorage.getItem("cart") || "[]").length)
      );
      setLoadingProducts((prev) => ({ ...prev, [product._id]: false }));
      return;
    }

    if (product.quantityByUser === 1) {
      showSuccessToast("Product added to cart");
    } else {
      showSuccessToast("Updated Successfully");
    }

    try {
      let response = await PostRequest("/addItemToCart", {
        productId: product?._id,
        quantity: product?.quantityByUser,
      });

      dispatch(
        setProduct({
          product: { ...product, addToCartStatus: "Update Cart" },
          index,
        })
      );
      dispatch(setCartLength(response.data.count));
    } catch (error: any) {
      if (
        error?.response?.data?.message === "Invalid User" ||
        error?.response?.data?.message === "Invalid token"
      ) {
        showInfoToast("Please Login");
      } else {
        showErrorToast(error?.response?.data?.message);
      }
    } finally {
      setLoadingProducts((prev) => ({ ...prev, [product._id]: false }));
    }
  };

  const handleIncrementForQuantity = async (product: any, index: number) => {
    setLoadingProducts((prev) => ({ ...prev, [product._id]: true }));
    const isLoggedIn = !!localStorage.getItem("userToken");

    try {
      if (!isLoggedIn) {
        // Handle locally for non-logged-in user
        const cartProducts = JSON.parse(localStorage.getItem("cart") || "[]");
        const productIndex = cartProducts.findIndex(
          (item: any) => item._id === product._id
        );
        const newQuantity = Math.min(
          (cartProducts[productIndex]?.quantityByUser || 0) + 1,
          cartProducts[productIndex]?.totalStock || Infinity
        );
        cartProducts[productIndex].quantityByUser = newQuantity;
        localStorage.setItem("cart", JSON.stringify(cartProducts));

        dispatch(
          setProduct({
            product: { ...product, quantityByUser: newQuantity },
            index,
          })
        );

        showSuccessToast("Updated Successfully");

        dispatch(setCartLength(cartProducts.length));
      } else {
        // Update quantity via API for logged-in user
        const newQuantity = Math.min(
          product.quantityByUser + 1,
          product.totalStock
        );
        const response = await PostRequest("/updateItemQuantity", {
          productId: product?._id,
          quantity: product.quantityByUser + 1,
        });

        dispatch(
          setProduct({
            product: { ...product, quantityByUser: product.quantityByUser + 1 },
            index,
          })
        );

        // dispatch(setCartLength(response.data.count));
        showSuccessToast("Updated Successfully");
      }
    } catch (error: any) {
      showErrorToast(error?.response?.data?.message);
    } finally {
      setLoadingProducts((prev) => ({ ...prev, [product._id]: false }));
    }
  };

  const handleDecrementForQuantity = async (product: any, index: number) => {
    setLoadingProducts((prev) => ({ ...prev, [product._id]: true }));
    const isLoggedIn = !!localStorage.getItem("userToken");

    try {
      if (!isLoggedIn) {
        // Handle locally for non-logged-in user
        const cartProducts = JSON.parse(localStorage.getItem("cart") || "[]");
        const productIndex = cartProducts.findIndex((item: any) => item._id === product._id);

        if (cartProducts[productIndex].quantityByUser > 1) {
          cartProducts[productIndex].quantityByUser -= 1;
          localStorage.setItem("cart", JSON.stringify(cartProducts));

          dispatch(
            setProduct({
              product: {
                ...product,
                quantityByUser: cartProducts[productIndex].quantityByUser,
              },
              index,
            })
          );

          showSuccessToast("Updated Successfully");
          dispatch(setCartLength(cartProducts.length));
        } else {
          // Remove product from cart if quantity is 1
          cartProducts.splice(productIndex, 1);
          localStorage.setItem("cart", JSON.stringify(cartProducts));

          dispatch(
            setProduct({
              product: {
                ...product,
                quantityByUser: 0,
                addToCartStatus: "Add to cart",
              },
              index,
            })
          );
          dispatch(setCartLength(cartProducts.length));
        }
      } else {
        // Handle API call for logged-in user
        if (product.quantityByUser === 1) {
          const response = await PostRequest("/removeItemFromCart", {
            productId: product?._id,
          });

          if (response.status === 200) {
            const cartProducts = JSON.parse(
              localStorage.getItem("cart") || "[]"
            );
            cartProducts.splice(index, 1);
            localStorage.setItem("cart", JSON.stringify(cartProducts));
            dispatch(
              setProduct({
                product: {
                  ...product,
                  quantityByUser: 1,
                  addToCartStatus: "Add to cart",
                },
                index,
              })
            );
            dispatch(setCartLength(response.data.cart.items.length));
          }
        } else {
          const newQuantity = product.quantityByUser - 1;
          const response = await PostRequest("/updateItemQuantity", {
            productId: product?._id,
            quantity: newQuantity,
          });

          if (response.status === 200) {
            dispatch(
              setProduct({
                product: { ...product, quantityByUser: newQuantity },
                index,
              })
            );

            const cartProducts = JSON.parse(
              localStorage.getItem("cart") || "[]"
            );
            cartProducts[index].quantityByUser = newQuantity;
            localStorage.setItem("cart", JSON.stringify(cartProducts));
            dispatch(setCartLength(response.data.items.length));

            showSuccessToast("Updated Successfully");
          }
        }
      }
    } catch (error: any) {
      showErrorToast(error?.response?.data?.message);
    } finally {
      setLoadingProducts((prev) => ({ ...prev, [product._id]: false }));
    }
  };

  const getFilterLabel = (key: any, item: any, idx: any, prices: any) => {
    if (key === "Brands") {
      return item.name;
    } else {
      return item;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClickOnFilter = () => {
    setShowFilterOnMobile(() => true);
  };

  const handleClickOnClearAllFilter = () => {
    const subCategoryId: any = query.get("sub-category-id");
    const categoryId: any = query.get("category_id");

    if (categoryId) {
      navigate(`/product-list?category_id=${categoryId}&page=1`);
    } else {
      navigate(`/product-list?sub-cateogory-id=${subCategoryId}&page=1`);
    }
    setShowFilterOnMobile(false);
  };

  const handleClickOnCompare = (product: any) => {
    const isProductInComparison = compareTheseProducts.some(
      (compProduct: any) => compProduct._id === product._id
    );

    if (!isProductInComparison) {
      // If the product is not in the comparison list and the list has less than 5 products
      if (compareTheseProducts.length < 5) {
        if (
          !subCategoryIdForCompare ||
          product.subCategoryId === subCategoryIdForCompare
        ) {
          dispatch(addProductForComparison(product));
          dispatch(setSubCategoryIdForComparison(product.subCategoryId));
        } else {
          showErrorToast("Please compare similar products!");
        }
      }
    } else {
      // If the product is already in the comparison list, remove it
      dispatch(removeProductForComparison(product._id));
    }
  };

  const calculateDiscountPercentage = (
    salePrice: number,
    regularPrice: number
  ) => {
    const discountPercentage =
      ((regularPrice - salePrice) / regularPrice) * 100;
    return discountPercentage.toFixed(2); // Returns percentage with two decimal places
  };


  return (

    <>
      <RenderHelmetForCat metaInformations={metaInfoForProductListing} />

      <UpperBanner categoryName={category} brands={productFilter.Brands} subcategoryName={subcategory} brandName={brand}/>
      <div className="max-w-[95%] sm:w-[100%] mx-auto  relative sm:mt-[2rem] p-2 sm:p-0">


        <div className="catalog-toolbar flex justify-between items-center space-x-12 sm:space-x-2">
          <button
            className="mobile-catalog-toolbar__filter-button  sm:sm:text-[0.8rem] bg-transparent text-[#505050] border-0 flex items-center mr-2"
            data-toggle="off-canvas"
            data-target="mobile-filter-sidebar-panel"
            onClick={isMobile ? handleClickOnFilter : () => { }}
          >
            <span className="motta-svg-icon motta-svg-icon--filter">
              <svg
                width="20"
                height="20"
                aria-hidden="true"
                role="img"
                focusable="false"
                viewBox="0 0 32 32"
              >
                <path d="M8 14.4h3.2v-9.6h-3.2v3.2h-4.8v3.2h4.8z"></path>
                <path d="M24 17.6h-3.2v9.6h3.2v-3.2h4.8v-3.2h-4.8z"></path>
                <path d="M14.4 8h14.4v3.2h-14.4v-3.2z"></path>
                <path d="M3.2 20.8h14.4v3.2h-14.4v-3.2z"></path>
              </svg>
            </span>
            Filter
            <span className="count ml-8"></span>
          </button>

          {pageForProduct && totalPages && totalItems ? (
            <p className="sm:text-[0.8rem] text-nowrap">
              {pageForProduct}–{totalPages} of {totalItems} Results
            </p>
          ) : (
            ""
          )}

          <div className="flex items-center sm:text-[0.8rem] space-x-2 outline-none text-nowrap">
            <span>Sort by :</span>

            <select
              className="orderby p-2 sm:p-0 rounded outline-none "
              aria-label="Shop order"
              value={sortBy} // Set the selected value
              onChange={handleSortChange} // Handle change event
            >
              <option value="">Default</option>
              <option value="priceAsc">Low to High</option>
              <option value="priceDesc">High to Low</option>
              <option value="date">Latest</option>
              <option value="bestSellers">Best Sellers</option>
            </select>
          </div>
        </div>
        <hr className="sm:mb-[0.5rem] mt-1" />

        <div className="flex flex-wrap justify-between w-[100%] sm:justify-start">
          {/* filters */}
          <div
            className={
              showFilterOnMobile
                ? "fixed top-0 left-0  w-full h-full bg-gray-900 overflow-y-scroll flex justify-center items-center bg-opacity-50 z-10"
                : "basis-[20%]"
            }
          >
            <aside
              className={
                showFilterOnMobile
                  ? "top-[101px] sm:max-h-[57vh]  w-[74%] basis-[100%] absolute  overflow-y-scroll  border-[1.3px] border-solid border[#ddd] rounded-lg px-[0.8rem] mt-[0.75rem] bg-white"
                  : "basis-[20%] sm:hidden h-auto max-h-[100vh] overflow-y-scroll no-scrollbar border-[1.3px] border-solid border[#ddd] rounded-lg px-[0.4rem] mt-[0.75rem] bg-white"
              }
            >
              {Object.entries(productFilter).length > 0 && (
                <p
                  className={
                    isMobile
                      ? "flex justify-between  items-center mt-[0.5rem]"
                      : "flex justify-end items-center"
                  }
                >
                  <span
                    onClick={handleClickOnClearAllFilter}
                    className="text-sm text-red-600 mt-[0.5rem] text-end cursor-pointer"
                  >
                    Clear All Filters
                  </span>
                  {isMobile && (
                    <span
                      onClick={() => setShowFilterOnMobile(false)}
                      className="text-[1.2rem] text-red-600 mt-[0.5rem] text-end cursor-pointer"
                    >
                      <RxCross2 />
                    </span>
                  )}
                </p>
              )}
              <ul className="space-y-6 font-medium">
                {Object.entries(productFilter).length > 0 &&
                  Object.entries(productFilter).map(
                    ([key, value]: any, index) => {
                      if (key !== "maxPrice" && key !== "minPrice") {
                        return (
                          <div key={index} className="relative">
                            <div
                              className="flex items-center p-2 mt-[1rem] text-gray-900 rounded-lg focus:outline-none cursor-pointer"
                              onClick={() => toggleDropdown(key)}
                            >
                              <span className="flex-grow text-[0.95rem]">
                                {key === "Display Quality"
                                  ? "Display Quality"
                                  : key}
                              </span>
                              <span className="text-[1.3rem] ml-12">
                                {openCategory.includes(key) ? "-" : "+"}
                              </span>
                            </div>
                            {openCategory.includes(key) ? (
                              <ul className="grid grid-cols-1 gap-2">
                                {Array.isArray(value) &&
                                  value.map((item: any, idx: any) => {
                                    // Check if the checkbox should be checked based on the query params

                                    return (
                                      <li
                                        key={idx}
                                        className="block px-3 py-3 text-gray-800 hover:bg-gray-100 text-gray-500 cursor-pointer rounded-xl text-sm inline"
                                      >
                                        <label className="flex items-center">
                                          <input
                                            type="checkbox"
                                            checked={isCheckedOrNot(
                                              key,
                                              key === "Brands" ? item._id : item
                                            )}
                                            onChange={() =>
                                              handleCheckboxChange(
                                                key,
                                                key === "Brands" ? item._id : item
                                              )
                                            }
                                            className="mr-2"
                                          />
                                          {getFilterLabel(key, item, idx, value)}
                                        </label>
                                      </li>
                                    );
                                  })}
                              </ul>
                            ) : null}
                            <hr />
                          </div>
                        );
                      } else if (key === "maxPrice") {
                        return (
                          <div key={index} className="relative">
                            <div
                              className="flex items-center p-2 mt-[1rem] text-gray-900 rounded-lg focus:outline-none cursor-pointer"
                              onClick={() => toggleDropdown(key)}
                            >
                              <span className="flex-grow text-[0.95rem]">
                                Price Range
                              </span>
                              <span className="text-[1.3rem] ml-12">
                                {openCategory.includes(key) ? "-" : "+"}
                              </span>
                            </div>
                            {openCategory.includes(key) && (
                              <div className="price-filter">
                                <div className="flex items-center justify-center">
                                  <li className="block relative px-3 py-3 w-[100%] text-gray-500 cursor-pointer rounded-xl text-sm inline">
                                    <span className="absolute top-0 left-0 text-xs text-blue-600 ">
                                      {productFilter?.minPrice &&
                                        "₹" + productFilter?.minPrice}
                                    </span>
                                    <input
                                      type="range"
                                      min={productFilter.minPrice}
                                      max={productFilter.maxPrice}
                                      value={
                                        query.get("max_price") &&
                                        productFilter?.maxPrice
                                      }
                                      onChange={handleMaxChange}
                                      className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer "
                                    />
                                    {/* amount selected by user */}
                                    <span className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-full text-xs text-orange-600 bg-white px-2 py-1 rounded shadow">
                                      {maxPrice ? "₹" + maxPrice : "Select Price"}
                                    </span>
                                    <span className="ml-2 absolute top-0 right-0 text-xs text-blue-600 ">
                                      {"₹" + productFilter?.maxPrice}
                                    </span>
                                  </li>
                                </div>
                              </div>
                            )}{" "}
                            <hr />
                          </div>
                        );
                      }
                    }
                  )}
              </ul>
            </aside>
          </div>
          <div className="flex flex-wrap justify-evenly pb-8 basis-[80%] sm:basis-full sm:pb-4">
            {isLoading ? (
              <div className="absolute top-[30%] sm:static sm:text-center sm:w-[100%] sm:flex sm:justify-center sm:items-center sm:h-[50vh] sm:left-[45%] left-[60%]">
                <Loader />
              </div>
            ) : allProduct.length > 0 ? (
              allProduct.map((product: any, index: number) => {
                return (
                  <div
                    key={index}
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                    }}
                    className="m-[0.7rem] max-h-[450px] sm:m-[0.4rem] basis-[22%]  sm:py-[0.7rem] sm:px-[0.55rem] py-[1rem] pt-[0.5rem] px-[0.7rem] rounded-xl flex flex-col sm:basis-[42%] sm:w-[calc(50%-1.4rem)] relative"
                  >
                    <Link
                      to={`/product?name=${product.productName
                        .replace(/[^a-zA-Z0-9]/g, "-") // Replace all non-alphanumeric characters with dashes
                        .replace(/-+/g, "-") // Replace multiple dashes with a single dash
                        .toLowerCase() // Optional: Convert to lowercase for a cleaner URL
                        }&product_id=${product._id}`}
                      className="flex sm:mt-[0.9rem] justify-center items-center"
                    >
                      <LazyLoadImage
                        effect="blur"
                        src={
                          product?.images?.length > 0 && product?.images[0]?.url
                        }
                        alt={product.productName}
                        className="object-contain w-[100%] h-[200px] sm:h-[120px] transition duration-300 ease-in-out hover:scale-105"
                      />
                    </Link>
                    <Link
                      to={`/product?name=${product.productName}&product_id=${product._id}`}
                    >
                      <h4 className="text-normal w-[220px] sm:w-[auto] line-clamp-3 text-center mt-[0.5rem] sm:text-[0.9rem] text-gray-900 h-[3rem] overflow-hidden">
                        {product.productName}
                      </h4>
                    </Link>
                    <div className="text-[1rem] sm:text-[0.9rem] text-[#0F1111] font-semibold text-center mt-1">
                      <h2>
                        ₹{product?.salePrice}
                        <h3 className="text-gray-500 text-xs ml-[0.4rem] inline-block line-through">
                          ₹{product?.regularPrice}
                        </h3>
                      </h2>

                      <h4 className="!text-[#CD0B39] font-semibold ml-[0.3rem] !text-sm">
                        {calculateDiscountPercentage(
                          Number(product?.salePrice),
                          Number(product?.regularPrice)
                        ) + "% off"}
                      </h4>
                    </div>
                    {product.totalStock === 0 ? (
                      ""
                    ) : (
                      <>
                        <div
                          className={
                            product?.addToCartStatus !== "Update Cart"
                              ? "hidden"
                              : "py-3 px-1 sm:px-[0.15rem] bg-white w-[100%] rounded-lg flex items-center sm:m-0"
                          }
                        >
                          {product.addToCartStatus === "Update Cart" && (
                            <div className="w-full flex justify-between items-center gap-x-3">
                              <div className="relative w-full flex items-center">
                                <input
                                  id="hs-validation-name-error"
                                  className="w-[90%] sm:w-[45px] sm:h-[20px] text-sm outline-none py-[0.1rem] border-[0.8px] border-solid border-[#ddd] pl-[0.8rem] rounded-lg bg-transparent border-0 text-gray-800 focus:ring-0"
                                  type="number"
                                  value={product?.quantityByUser}
                                  data-hs-input-number-input=""
                                  aria-describedby="hs-validation-name-error-helper"
                                />
                                {loadingProducts[product._id] && (
                                  <div className="inline-block top-[-25px] right-[19px] absolute">
                                    <div className="lds-ring text-black">
                                      <div></div>
                                      <div></div>
                                      <div></div>
                                      <div></div>
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className="flex justify-end items-center gap-x-1.5 ">
                                {!loadingProducts[product._id] && (
                                  <>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        handleDecrementForQuantity(product, index)
                                      }
                                      className="size-6 sm:size-[1.25rem] inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
                                      data-hs-input-number-decrement=""
                                    >
                                      <svg
                                        className="flex-shrink-0 size-3.5"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      >
                                        <path d="M5 12h14"></path>
                                      </svg>
                                    </button>

                                    <button
                                      type="button"
                                      className="size-6 sm:size-[1.25rem] inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
                                      data-hs-input-number-increment=""
                                      onClick={() =>
                                        handleIncrementForQuantity(product, index)
                                      }
                                    >
                                      <svg
                                        className="flex-shrink-0 size-3.5"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      >
                                        <path d="M5 12h14"></path>
                                        <path d="M12 5v14"></path>
                                      </svg>
                                    </button>
                                  </>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    )}

                    {product.totalStock !== 0 ? (
                      <div
                        className={
                          product.addToCartStatus == "Update Cart"
                            ? "hidden"
                            : "flex justify-center items-center w-[100%] sm:my-[0.5rem]"
                        }
                      >
                        <button
                          onClick={() => {
                            if (!loadingProducts[product._id]) {
                              handleAddToCart(product, index);
                            }
                          }}
                          className={
                            loadingProducts[product._id]
                              ? "bg-indigo-700 text-white h-[33.13px] text-center w-[75%] sm:h-[25px] sm:mt-0 xss:w-[100%] relative sm:m-0 my-[0.7rem] rounded-lg  p-[0.4rem] sm:py-[0.2rem] text-sm sm:text-[0.63rem]"
                              : "bg-indigo-700 text-white text-center h-auto w-[75%] sm:h-[25px] sm:mt-0 xss:w-[100%] relative sm:m-0 my-[0.7rem] rounded-lg  p-[0.4rem] sm:py-[0.2rem] text-sm sm:text-[0.63rem]"
                          }
                        >
                          {loadingProducts[product._id] ? (
                            <div className="absolute top-[-19px] left-[38%] sm:left-[30%] sm:top-[-19px] h-[45px]">
                              <div className="lds-ring">
                                <div className="sm:!h-[18px] sm:!w-[18px] "></div>
                                <div className="sm:!h-[18px] sm:!w-[18px] "></div>
                                <div className="sm:!h-[18px] sm:!w-[18px] "></div>
                                <div className="sm:!h-[18px] sm:!w-[18px] "></div>
                              </div>
                            </div>
                          ) : (
                            <>
                              Add to cart
                              <FaShoppingCart className="inline mb-[0.2rem] ml-[0.2rem]" />
                            </>
                          )}
                        </button>
                      </div>
                    ) : (
                      <div
                        className={
                          "flex justify-center items-center cursor-not-allowed w-[100%] sm:my-[0.5rem]"
                        }
                      >
                        <button className="bg-[#B7B7B7] cursor-not-allowed text-[#fff] text-center h-auto w-[75%] sm:h-[25px] sm:mt-0 xss:w-[100%] relative sm:m-0 my-[0.7rem] rounded-lg  p-[0.4rem] sm:py-[0.2rem] text-sm sm:text-[0.63rem]">
                          Out Of Stock
                        </button>
                      </div>
                    )}
                  </div>
                );
              })
            ) : (
              <p className="flex justify-center w-[100%] mt-[3rem] text-red-500 font-bolder">
                Oops no product Found
              </p>
            )}
          </div>
        </div>
        {allProduct.length > 0 && <Pagination />}
      </div>
      <BottomDesc metaInfo={metaInfoForProductListing}/>
    </>

  );
};

export default ProductListing;
