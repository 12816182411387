import { Link } from 'react-router-dom';

interface UpperBannerProps {
    categoryName: string | null;
    subcategoryName: string | null;
    brands: { _id: string; name: string }[];
    brandName:string |null;
}

const UpperBanner = ({ categoryName, subcategoryName, brands,brandName }: UpperBannerProps) => {

    return (
        <>
            {/* Breadcrumb Navigation */}
            <div className="mt-[100px] ml-4">
                <p className="text-lg">
                    <Link to="/" className=" text-lg hover:text-blue-400 transition-colors">
                        Home
                    </Link>
                    {' '} &gt; {' '}
                    {categoryName && (
                        <>
                   <span className=" text-lg">{categoryName}</span>
                        </>
                    )}
                    {subcategoryName && (
                        <>
                         
                            <span className="text-lg">{subcategoryName}</span>
                        </>
                    )}
                    {
                        brandName && (
                            <>
                            <span className='text-lg'>{brandName}</span>
                            </>
                        )
                    }
                </p>
            </div>

            {/* Banner with Category Title and Brands */}
            <div className="relative h-[200px] w-full px-5 mt-2">
                <img
                    src="/productListBanner/productList.webp"
                    className="relative object-center object-cover h-full w-full"
                    alt=""
                />

                {/* Category Title */}
                <div className="absolute inset-0 flex items-center justify-center z-6 text-center text-white">
                    <h1 className="text-4xl font-bold  sm:text-3xl md:text-2xl lg:text-xl xl:text-lg">
                        {subcategoryName || categoryName || brandName}
                    </h1>
                </div>
            </div>
        </>
    );
};

export default UpperBanner;
