import React from 'react';

const Sitemap = () => {
  const sitemapLinks = [
    {
      title: 'Clearance Sale',
      loc: 'https://www.cayroshop.com/product-list?category=Clearance-Sale&category_id=66e15430a3b1fac4854a1e9e&page=1',
    },
    {
      title: 'Audio Products',
      loc: 'https://www.cayroshop.com/product-list?category=Audio&category_id=67135ed9979bc6cd210b0a6b&page=1',
    },
    {
      title: 'Kitchen Appliances',
      loc: 'https://www.cayroshop.com/product-list?category=Kitchen-Appliances&category_id=66b1e1365472ab860dac9d2a&page=1',
    },
    {
      title: 'Mobile Phones',
      loc: 'https://www.cayroshop.com/product-list?category=Mobiles&category_id=66b1de805472ab860dac9d1c&page=1',
    },
    {
      title: 'Personal Care Products',
      loc: 'https://www.cayroshop.com/product-list?category=Personal-Care&category_id=66b1df7b5472ab860dac9d22&page=1',
    },
    {
      title: 'Audio',
      loc: 'https://www.cayroshop.com/product-list?category=Audio&category_id=67135ed9979bc6cd210b0a6b&page=1',
    },
    {
      title: 'Home Appliances',
      loc: 'https://www.cayroshop.com/product-list?category=Home-Appliances&category_id=66b1ea535472ab860dac9d2d&page=1',
    },
    {
      title: 'Hot Deals',
      loc: 'https://www.cayroshop.com/product-list?category=Hot-Deals&category_id=672dd6c82e201e6f17c5ef19&page=1',
    },
    {
      title: 'Laptops',
      loc: 'https://www.cayroshop.com/product-list?category=Laptops&category_id=67067b7c915de597bb4bc686&page=1',
    },
  ];

  return (
    <div className="min-h-screen w-full bg-gray-100 py-8 mt-[100px]">
      <div className="bg-white rounded-lg shadow-lg p-8 h-full flex items-center justify-center">
        <div className="flex flex-wrap justify-center sm:justify-between text-lg font-medium text-gray-700">
          {sitemapLinks.map((link, index) => (
            <div key={index} className="text-center mx-2 mb-4 sm:mb-0">
              <a
                href={link.loc}
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-blue-500 transition-colors duration-200"
              >
                {link.title}
              </a>
              
              {index < sitemapLinks.length - 1 && (
                <span className="mx-2 text-gray-400  lg:inline">|</span>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sitemap;
