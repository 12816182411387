import React, { useEffect, useState } from "react";
import AccountSidebar from "../SideBar";
import { GetRequest } from "../../../api/Request";
import Loader from "../../../common/loader/Loader";
import { showErrorToast, showInfoToast } from "../../../utils/toast";
import { useDispatch, useSelector } from "react-redux";
import { IsLoading, IsLoggedIn, UserInfo } from "../../../slice/UserSlice";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import OrderSuccess from "../../banners/OrderSuccess";
import { clearCart } from "../../../slice/CartSlice";
import { AppDispatch } from "../../../store/store";
import PaginationForOrder from "../../../pagination/PaginationForOrder";
import { FaDownload } from "react-icons/fa";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Helmet } from "react-helmet";

const OrderList: React.FC = () => {
  const [allOrder, setAllOrder] = useState<any>([]);
  const [isLoadingForOrder, setIsLoadingForOrder] = useState<boolean>(false);
  const userDetail: any = useSelector(UserInfo);
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const query: any = new URLSearchParams(location.search);
  const [pageNumber, setPageNumber] = useState(query.get("page"));
  const [totalPages, setTotalPages] = useState();
  const [totalItems, setTotalItems] = useState();
  const navigate = useNavigate();
  const isLoadingForUser = useSelector(IsLoading);
  const userIsLoggedIn = useSelector(IsLoggedIn);

  useEffect(() => {
    if (!localStorage.getItem("userToken")) {
      navigate("/");
    } else {
      getAllOrder();
      setPageNumber(() => query.get("page"));
      window.scrollTo(0, 0);
    }
  }, [userIsLoggedIn, isLoadingForUser, query.get("page")]);

  const handleDownload = (url: any) => {
    window.location.href = url;
    // code for download the image, but cors issue exists
    // fetch(url)
    //   .then((response) => {
    //     if (!response.ok) {
    //       throw new Error("Network response was not ok");
    //     }
    //     return response.blob();
    //   })
    //   .then((blob) => {
    //     const link = document.createElement("a");
    //     link.href = window.URL.createObjectURL(blob);
    //     link.download = "invoice.pdf"; // Specify the filename
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    //     window.URL.revokeObjectURL(link.href); // Clean up
    //   })
    //   .catch((error) =>
    //     showInfoToast(
    //       "Sorry for inconvenience, we will provide the invoice soon!"
    //     )
    //   );
  };

  const getAllOrder = async () => {
    setIsLoadingForOrder(true);
    try {
      let response = await GetRequest(`getAllOrder?page=${query.get("page")}`);
      if (response.status === 200) {
        setAllOrder(() => response.data.orders);
        setTotalPages(() => response.data.totalPages);
        setTotalItems(() => response.data.totalOrders);
      }
    } catch (error) {
      showErrorToast("Error in fetching orders");
    } finally {
      setIsLoadingForOrder(false);
    }
  };

  const handleAddReview = (productId: string, orderId: string) => {
    // Construct the query string
    const queryString = new URLSearchParams({ productId, orderId }).toString();
    navigate(`/review?${queryString}`);
  };

  useEffect(() => {
    if (
      searchParams.get("order_id") &&
      searchParams.get("message") &&
      searchParams.get("amount")
    ) {
      dispatch(clearCart());
    }
  }, []);

  const getOrderStatusWidth = (status: any) => {
    switch (status) {
      case "Placed":
        return "25%";
      case "Accepted":
        return "50%"; // For example, if accepted is 33%
      case "Shipped":
        return "75%"; // If shipped is 66%
      case "Delivered":
        return "100%"; // If delivered is 100%
      case "Cancelled":
        return "100%";
      default:
        return "0%"; // Default width if no status is set
    }
  };

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div className="flex mt-[8rem] items-start mx-auto my-0  justify-evenly flex-wrap  mb-[1rem]">
        <div className="basis-[20%] sm:hidden mt-[1rem] border-r-[1px] border-r-[#ddd]">
          <AccountSidebar />
        </div>
        {searchParams.get("order_id") &&
          searchParams.get("message") &&
          searchParams.get("amount") ? (
          <OrderSuccess
            orderId={searchParams.get("order_id")}
            message={searchParams.get("message")}
            amount={searchParams.get("amount")}
          />
        ) : (
          ""
        )}
        <div className="basis-[80%] sm:basis-[100%]">
          <div className="ml-4 sm:ml-4">
            <h1 className="font-medium  text-2xl sm:text-xl sm:mb-3">
              My Orders
            </h1>
          </div>
          <div className=" p-6 flex flex-col w-[90%] sm:w-[100%] ml-[1rem] flex-start:start rounded-xl sm:p-[0.6rem] sm:ml-[0rem]">
            <div className="flex md:flex-col flex-row lg:space-x-6">
              <div className="flex-grow">
                {allOrder?.length > 0 ? (
                  allOrder?.map((order: any, index: number) => (
                    <div className="border-[0.5px] border-[#ddd] mb-[2rem] rounded-xl p-6 sm:p-3">
                      <div className="flex flex-col">
                        <p className="mb-[0.25rem] flex flex-wrap justify-between items-start">
                          <span className="text-gray-600 sm:text-sm">
                            Order ID:{order?._id}
                          </span>
                          {order?.orderStatus !== "Placed" && (
                          <button
                            onClick={() => handleDownload(order.invoice.url)}
                            className="text-blue-500 text-sm sm:block  flex items-center cursor-pointer"
                          >
                            <FaDownload className="mr-[0.4rem] inline-block" />
                            Download Invoice
                          </button>
                        )}


                        </p>
                        <p className="mb-1 mt-1 sm:mt-[-0.1rem] sm:text-sm flex flex-wrap justify-between items-start">
                          <span className="text-gray-600 ">
                            Order Status : {order?.orderStatus}
                          </span>
                          <span className="text-gray-600">
                            Payment Status : {order?.paymentStatus}
                          </span>
                        </p>

                       
                      </div>
                      <hr className="border-b-1 mt-2"></hr>
                      <div
                        key={index}
                        className="flex flex-col mt-2 justify-between"
                      >
                        {order.productDetailsInfo.length > 0 &&
                          order.productDetailsInfo.map((item: any) => {
                            return (
                              <div
                                className="flex sm:my-[0.5rem]"
                                key={item._id}
                              >
                                <Link
                                  className="w-[100px] h-[100px] object-contain inline-block"
                                  to={`/product?name=${item.productName}&product_id=${item._id}`}
                                >
                                  <LazyLoadImage
                                    effect="blur"
                                    className="w-[100px] h-[100px] mr-3 object-contain rounded-md"
                                    src={
                                      item?.images?.length > 0 &&
                                      item?.images[0]?.url
                                    }
                                    alt="Product Image"
                                  />
                                </Link>

                                <div className="ml-[0.5rem]">
                                  <Link
                                    to={`/product?name=${item.productName}&product_id=${item._id}`}
                                  >
                                    <h2 className="text-[16px] sm:text-sm font-[500]">
                                      {item.productName}
                                    </h2>
                                  </Link>

                                  <h2 className="text-gray-600 text-sm">
                                    Quantity - {item.quantity}
                                  </h2>
                                  <p className="text-orange-600 text-[15px] sm:text-sm">
                                    Price : ₹{item.salePrice}
                                  </p>

                                  {/* Move the Write a review text below the price */}
                                  <div
                                    className="text-sky-400 cursor-pointer mt-2"
                                    onClick={() =>
                                      handleAddReview(item._id, order._id)
                                    } // Pass productId and orderId
                                  >
                                    Write a review
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>

                      <div className="p-[0.8rem] sm:p-0">
                        <div className="md:w-fit bg-gray-50 p-2 sm:p-[0.4rem] rounded-lg lg:flex-none">
                          <div>
                            <h4 className=" sm:text-xs font-medium text-gray-600 underline">
                              Delivery address :
                            </h4>
                            {
                              <div>
                                <span className=" text-[15px]">
                                  {order?.shippingAddress?.name + ", "}
                                </span>
                                <span className=" text-[15px]">
                                  {order?.shippingAddress?.mobileNumber + ", "},
                                </span>
                                <span>
                                  {order?.shippingAddress?.address1 + ", "},{" "}
                                </span>
                                <span>
                                  {order?.shippingAddress?.address2 + ", "}{" "}
                                </span>
                                <span>
                                  {order?.shippingAddress?.city + ", "}{" "}
                                </span>
                                <span>
                                  {order?.shippingAddress?.state + ", "}{" "}
                                </span>
                                <span>
                                  {order?.shippingAddress?.postalCode + ", "}{" "}
                                </span>
                                <span>{order?.shippingAddress?.country} </span>
                              </div>
                            }
                          </div>
                        </div>
                        <div>
                          <div className="relative pt-2 mt-3">
                            {/* Calculate the width based on order status */}
                            <div className="overflow-hidden h-2 text-xs flex bg-gray-200 rounded">
                              <div
                                style={{
                                  width: getOrderStatusWidth(
                                    order?.orderStatus
                                  ),
                                  backgroundColor:
                                    order?.orderStatus === "Cancelled"
                                      ? "#DC2626"
                                      : "#4F46E5", // Red for Cancelled, Indigo for others
                                }}
                                className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center"
                              ></div>
                            </div>

                            <div className="flex xs:flex-col flex-row justify-between text-sm text-gray-500 mt-2 text-center md:text-left">
                              <span
                                className={
                                  order.orderStatus === "Placed"
                                    ? "text-indigo-600 font-medium mb-2 md:mb-0"
                                    : "mb-2 md:mb-0"
                                }
                              >
                                Placed
                              </span>

                              <span
                                className={
                                  order.orderStatus === "Accepted"
                                    ? "text-indigo-600 font-medium mb-2 md:mb-0"
                                    : "mb-2 md:mb-0"
                                }
                              >
                                Accepted
                              </span>
                              <span
                                className={
                                  order.orderStatus === "Shipped"
                                    ? "text-indigo-600 font-medium mb-2 md:mb-0"
                                    : "mb-2 md:mb-0"
                                }
                              >
                                Shipped
                              </span>
                              <span
                                className={
                                  order.orderStatus === "Delivered"
                                    ? "text-indigo-600 font-medium mb-2 md:mb-0"
                                    : "mb-2 md:mb-0"
                                }
                              >
                                Delivered
                              </span>

                              {order?.orderStatus === "Cancelled" && (
                                <span className="text-red-600 font-medium mb-2 md:mb-0">
                                  Cancelled
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        {/*    <span className="text-indigo-600 font-medium mb-2 md:mb-0">
                              Order placed
                            </span> */}
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-red-400 text-center">No Order Found</p>
                )}
                {allOrder.length > 0 && (
                  <PaginationForOrder
                    totalPages={totalPages}
                    totalItems={totalItems}
                    pageNumber={pageNumber}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderList;
