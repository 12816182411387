import React, { useState, useRef, useEffect } from "react";
import Lottie from "lottie-react";
import reviewAnimation from "../../loti/review.json"; // Ensure this path is correct
import { useSelector } from "react-redux";
import {
  DeleteRequest,
  GetRequest,
  PatchRequest,
  PatchRequestWithFile,
  PostRequestForFile,
} from "../../api/Request";
import { useLocation, useNavigate } from "react-router-dom"; // Import useLocation for query parameters
import { Helmet } from "react-helmet";

interface Review {
  user: string;
  rating: number;
  comment: string;
  imageUrl?: string; // Optional image URL
}

export const ReviewComponent: React.FC = () => {
  const { user } = useSelector((state: any) => state.user);
  const location = useLocation();
  const navigate = useNavigate();

  const [rating, setRating] = useState<number>(0);
  const [comment, setComment] = useState<string>("");
  const [image, setImage] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null); // Image preview state
  const [images, setImages] = useState<{ url: string; publicId: string }[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const queryParams = new URLSearchParams(location.search);
  const productId = queryParams.get("productId");
  const orderId = queryParams.get("orderId");
  const reviewId = queryParams.get("reviewId");
  const [reviewIdForImage, setReviewIdForImage] = useState<string>("");

  useEffect(() => {
    const fetchReview = async () => {
      let endpoint = "";
      if (orderId && productId) {
        endpoint = `/getReview?orderId=${orderId}&productId=${productId}`;
      } else if (reviewId) {
        endpoint = `/getReviewById/${reviewId}`;
      }

      if (endpoint) {
        try {
          const response = await GetRequest(endpoint);
          if (response && response.data) {
            const reviewData = response.data;
            setRating(parseInt(reviewData.rating));
            setReviewIdForImage(reviewData._id); // Set review ID for image deletion
            setComment(reviewData.comment || "");
            if (reviewData.images) {
              setImages(
                reviewData.images.map((img: any) => ({
                  url: img.url,
                  publicId: img.publicId,
                }))
              );
            }
          }
        } catch (error) {
          console.error("Error fetching review:", error);
        }
      }
    };

    fetchReview();
  }, [orderId, productId, reviewId]);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedImage = e.target.files?.[0] || null;
    setImage(selectedImage);
    if (selectedImage) {
      const previewUrl = URL.createObjectURL(selectedImage);
      setImagePreview(previewUrl); // Set the image preview
    }
  };

  const handleFileClick = () => {
    fileInputRef.current?.click();
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setMessage("");

    try {
      const formData = new FormData();
      formData.append("comment", comment);
      formData.append("productId", productId || "");
      formData.append("rating", rating.toString());

      if (orderId) {
        formData.append("orderId", orderId);
      }

      if (reviewId) {
        formData.append("reviewId", reviewId);
      }

      if (image) {
        formData.append("file", image);
      }

      const endpoint = reviewId ? `/update-review/${reviewId}` : "/add-review";
      const response = reviewId
        ? await PatchRequestWithFile(endpoint, formData)
        : await PostRequestForFile(endpoint, formData);

      setMessage("Review submitted successfully!");
      setRating(0);
      setComment("");
      setImage(null);
      setImages([]);
      setImagePreview(null); // Reset preview after submission
    } catch (error) {
      console.error("Error submitting review:", error);
      setMessage("An error occurred");
    } finally {
      setLoading(false);
      navigate(-1); // Navigate back after submission
    }
  };

  const removeImage = async (publicId: string) => {
    try {
      await DeleteRequest(
        `/deleteReviewImage?reviewId=${reviewIdForImage}&publicId=${publicId}`
      );
      setImages(images.filter((image) => image.publicId !== publicId));
    } catch (error) {
      console.error("Error deleting image:", error);
      setMessage("Failed to delete image.");
    }
  };

  const closePopup = () => {
    setShowPopup(false);
    navigate(-1); // Navigate back when closing the popup
  };

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="bg-white p-8 shadow-lg rounded-lg w-full max-w-md">
          <h3 className="text-3xl font-bold mb-6 text-center text-gray-700">
            Submit a Review
          </h3>
          {message && (
            <div className="mb-4 text-green-500 text-center">{message}</div>
          )}

          <form onSubmit={handleSubmit}>
            <div className="mb-6">
              <label className="block text-gray-600 mb-2">Rating:</label>
              <div className="flex items-center space-x-2">
                {[1, 2, 3, 4, 5].map((star) => (
                  <span
                    key={star}
                    className={`cursor-pointer text-4xl ${
                      rating >= star ? "text-yellow-500" : "text-gray-300"
                    }`}
                    onClick={() => setRating(star)}
                  >
                    ★
                  </span>
                ))}
              </div>
            </div>

            <div className="mb-6">
              <label htmlFor="comment" className="block text-gray-600 mb-2">
                Comment:
              </label>
              <textarea
                id="comment"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                rows={4}
                required
                className="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
              />
            </div>

            <div className="mb-6 flex flex-wrap">
              {images.map((img, index) => (
                <div key={index} className="relative mr-4 mb-4">
                  <img
                    src={img.url}
                    alt={`Review Image ${index + 1}`}
                    className="w-16 h-16 object-cover rounded"
                  />
                  <button
                    type="button"
                    onClick={() => removeImage(img.publicId)}
                    className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center"
                  >
                    &times;
                  </button>
                </div>
              ))}
              {/* Show image preview if available */}
              {imagePreview && (
                <div className="relative mr-4 mb-4">
                  <img
                    src={imagePreview}
                    alt="Image Preview"
                    className="w-16 h-16 object-cover rounded"
                  />
                </div>
              )}
            </div>

            <div className="mb-6 flex items-center">
              <input
                type="file"
                id="image"
                accept=".png,.jpg,.jpeg"
                onChange={handleImageChange}
                className="hidden"
                ref={fileInputRef}
              />
              <div
                onClick={handleFileClick}
                className="flex items-center border border-gray-300 rounded-lg p-2 bg-gray-50 hover:bg-gray-100 transition duration-200 cursor-pointer"
              >
                <span className="text-gray-600">Upload an Image</span>
              </div>
            </div>

            <button
              type="submit"
              className={`bg-blue-500 text-white py-3 px-4 rounded-lg w-full transition duration-200 ${
                loading ? "bg-blue-400 cursor-not-allowed" : "hover:bg-blue-600"
              }`}
              disabled={loading}
            >
              {loading ? (
                <div className="flex items-center justify-center">
                  <div className="loader mr-2"></div>
                  Submitting...
                </div>
              ) : (
                "Submit Review"
              )}
            </button>
          </form>
        </div>

        {/* Popup for missing reviewId */}
        {showPopup && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-4 rounded shadow-lg">
              <h3 className="text-lg font-bold">Notice</h3>
              <p>You cannot submit a review without a review ID.</p>
              <button
                onClick={closePopup}
                className="mt-4 bg-blue-500 text-white py-2 px-4 rounded"
              >
                Close
              </button>
            </div>
          </div>
        )}

        <style>{`
        .loader {
          border: 4px solid rgba(255, 255, 255, 0.2);
          border-radius: 50%;
          border-top-color: #ffffff;
          width: 20px;
          height: 20px;
          animation: spin 1s infinite linear;
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      `}</style>
      </div>
    </>
  );
};
