import { useState } from "react";

const ProductDescription = ({ product }: { product: any }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  // Truncate description to a certain length, e.g., 200 characters
  const truncatedDescription = product?.description?.slice(0, 200);

  return (
    <div>
      <h2 className="text-lg font-semibold mb-2 sm:text-sm">Product Description</h2>
      <ul className="list-disc list-inside text-gray-700">
        <div
          className="list-none sm:text-sm"
          dangerouslySetInnerHTML={{
            __html: isExpanded ? product?.description : truncatedDescription,
          }}
        />
      </ul>
      {product?.description?.length > 200 && (
        <button
          onClick={handleToggle}
          className="text-blue-600 text-sm mt-2 focus:outline-none"
        >
          {isExpanded ? "See Less" : "See More"}
        </button>
      )}
    </div>
  );
};

export default ProductDescription;
