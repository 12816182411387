import React from "react";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container mt-[7rem] w-[95%] mx-auto my-8 p-6 bg-white shadow-md rounded-lg">
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <h1 className="text-xl sm:text-lg font-bold mb-4">Privacy Policy</h1>
      <p className="mb-4">
        At <strong>Cayro Enterprises</strong>, we are committed to protecting
        the privacy and security of our customers' personal information. This
        Privacy Policy outlines how we collect, use, disclose, and protect your
        information when you visit our website or make a purchase. By using our
        website, you agree to the terms of this Privacy Policy.
      </p>

      <h2 className="text-lg font-semibold mt-6">1. Information We Collect</h2>
      <p className="mb-2">
        We collect various types of information to improve your experience and
        provide our services effectively:
      </p>
      <h3 className="font-semibold mt-4">1.1 Personal Information</h3>
      <ul className="list-disc ml-5 mb-4">
        <li>Name</li>
        <li>Email address</li>
        <li>Phone number</li>
        <li>Shipping and billing address</li>
        <li>Payment information (e.g., credit card details)</li>
      </ul>
      <h3 className="font-semibold">1.2 Non-Personal Information</h3>
      <ul className="list-disc ml-5 mb-4">
        <li>Browser type and version</li>
        <li>Device information (e.g., device model, operating system)</li>
        <li>IP address</li>
        <li>Pages visited on our website and the time spent on each page</li>
        <li>Referring site or source through which you accessed our site</li>
        <li>Other analytics and usage data</li>
      </ul>

      <h2 className="text-lg font-semibold mt-6">
        2. How We Use Your Information
      </h2>
      <ul className="list-disc ml-5 mb-4">
        <li>
          To process and fulfill your orders: Including shipping products,
          providing order confirmations, and tracking information.
        </li>
        <li>
          To improve our website and services: By analyzing usage patterns and
          customer feedback.
        </li>
        <li>
          To communicate with you: Sending emails regarding order status,
          promotional offers, new product information, and customer support.
        </li>
        <li>
          To enhance security: Detecting and preventing fraud, unauthorized
          access, and other illegal activities.
        </li>
        <li>
          To comply with legal obligations: Responding to subpoenas, court
          orders, or other legal requests.
        </li>
      </ul>

      <h2 className="text-lg font-semibold mt-6">
        3. How We Share Your Information
      </h2>
      <ul className="list-disc ml-5 mb-4">
        <li>
          Service Providers: We may share information with third-party service
          providers who assist us in processing payments, shipping orders, and
          providing customer support.
        </li>
        <li>
          Business Transfers: In the event of a merger, acquisition, or sale of
          all or a portion of our assets, customer information may be
          transferred as part of that business deal.
        </li>
        <li>
          Legal Compliance: We may disclose your information to comply with
          applicable laws, regulations, legal processes, or governmental
          requests.
        </li>
        <li>
          Protecting Rights: To enforce our terms and conditions, protect our
          rights, privacy, safety, or property, and that of our users or others.
        </li>
      </ul>

      <h2 className="text-lg font-semibold mt-6">
        4. Cookies and Tracking Technologies
      </h2>
      <p className="mb-4">
        We use cookies and similar tracking technologies to enhance your
        browsing experience, analyze website traffic, and understand how you
        interact with our website. You can choose to accept or decline cookies.
        Most web browsers automatically accept cookies, but you can usually
        modify your browser settings to decline cookies if you prefer. However,
        this may prevent you from taking full advantage of the website.
      </p>

      <h2 className="text-lg font-semibold mt-6">5. Data Security</h2>
      <p className="mb-4">
        We are committed to ensuring the security of your personal information.
        We implement appropriate physical, electronic, and managerial procedures
        to safeguard and secure the information we collect online. While we
        strive to protect your personal data, no security system is completely
        secure, and we cannot guarantee the absolute security of your
        information.
      </p>

      <h2 className="text-lg font-semibold mt-6">6. Data Retention</h2>
      <p className="mb-4">
        We retain your personal information only for as long as necessary to
        fulfill the purposes for which it was collected or to comply with legal,
        accounting, or reporting obligations. When your personal information is
        no longer required, we will securely delete or anonymize it.
      </p>

      <h2 className="text-lg font-semibold mt-6">7. Your Rights and Choices</h2>
      <ul className="list-disc ml-5 mb-4">
        <li>
          Access your personal information: Request a copy of the personal data
          we hold about you.
        </li>
        <li>
          Correct your personal information: Request correction of any
          inaccuracies in your personal data.
        </li>
        <li>
          Delete your personal information: Request deletion of your personal
          information, subject to certain legal obligations.
        </li>
        <li>
          Opt-out of marketing communications: Choose not to receive promotional
          emails by following the unsubscribe instructions included in such
          emails or by contacting us directly.
        </li>
      </ul>
      <p>
        To exercise any of these rights, please contact us at{" "}
        <a href="mailto:info@cayroshop.com" className="text-blue-600 underline">
          info@cayroshop.com
        </a>
        .
      </p>

      <h2 className="text-lg font-semibold mt-6">8. Third-Party Links</h2>
      <p className="mb-4">
        Our website may contain links to third-party websites. We are not
        responsible for the privacy practices or content of these third-party
        sites. We encourage you to review the privacy policies of any
        third-party websites you visit.
      </p>

      <h2 className="text-lg font-semibold mt-6">9. Children's Privacy</h2>
      <p className="mb-4">
        Our website is not intended for use by children under the age of 10. We
        do not knowingly collect personal information from children under 10. If
        you believe we may have collected information from a child under 10,
        please contact us immediately, and we will promptly remove such
        information from our records.
      </p>

      <h2 className="text-lg font-semibold mt-6">
        10. Changes to This Privacy Policy
      </h2>
      <p className="mb-4">
        We may update this Privacy Policy from time to time to reflect changes
        in our practices or for other operational, legal, or regulatory reasons.
        We will notify you of any significant changes by posting the new policy
        on our website and updating the effective date. Your continued use of
        the website after any changes signifies your acceptance of the updated
        Privacy Policy.
      </p>

      <h2 className="text-lg font-semibold mt-6">11. Contact Information</h2>
      <p className="mb-4">
        If you have any questions or concerns about this Privacy Policy or how
        we handle your personal information, please contact us at:
      </p>
      <ul className="list-disc ml-5">
        <li>
          Email:{" "}
          <a
            href="mailto:info@cayroshop.com"
            className="text-blue-600 underline"
          >
            info@cayroshop.com
          </a>
        </li>
        <li>Phone: 9599383349</li>
        <li>
          Address: Dcg, 104, Dlf Corporate Greens Sector 74A, Gurugram,
          Haryana-122001
        </li>
      </ul>
    </div>
  );
};

export default PrivacyPolicy;
