import { useMediaQuery } from "@react-hook/media-query"
import { useEffect, useState } from "react"
import { FaShippingFast } from "react-icons/fa"
import { MdSupportAgent } from "react-icons/md"
import { RiSecurePaymentFill } from "react-icons/ri"
import { useNavigate } from "react-router-dom"

const Banners = () => {
    const isMobile = useMediaQuery("(max-width: 520px)");
    const navigate = useNavigate()
    const products = [
        {
            name: "REFRIGERATOR",
            description: "LG 242 L FROST FREE DOUBLE DOOR",
            price: "₹24499",
            image: "/newArrivals/refrigerator.svg",
            url: "/product?name=LG-242-L-Frost-Free-Double-Door-2-Star-Convertible-Refrigerator-(Dazzle-Steel-GL-N292BDSY)&product_id=672893e79bcfd5d639de188a",
        },
        {
            name: "Small Personal Care",
            description: "Lazer 1500W Auto Immersion Heater Rod",
            price: "₹669",
            image: "/newArrivals/water_heater.svg",
            url: "/product?name=Lazer-1500W-Auto-Immersion-Heater-Rod-with-Copper-Heating-Element&product_id=670f66aefb24225aa2de803e",
        },
        {
            name: "Vacuum Cleaner",
            description: "AGARO ACE 1600W Wet & Dry Vacuum Cleaner",
            price: "₹5699",
            image: "/newArrivals/vaccum.svg",
            url: "/product?name=AGARO-ACE-1600W-Wet-&-Dry-Vacuum-Cleaner-(Grey-and-Black)&product_id=6749963452fb70f6b1645150",
        },
        {
            name: "Washing Machine",
            description: "Lg, 9Kg 5 Star",
            price: "₹38999",
            image: "/newArrivals/washing_machine.svg",
            url: "/product?name=Lg-9Kg-5-Star-Front-Load-Washing-Machine-AI-Direct-Drive-Platinum-Silver-FHP1209Z7P&product_id=66c9b5b7ad0fde84ea1a78e9",
        },
    ];

    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((prev) => (prev + 1) % products.length);
        }, 3000); // Slide changes every 3 seconds
        return () => clearInterval(interval); // Clear interval on component unmount
    }, [products.length]);

    return <>
        <div className="flex  w-full p-4 md:flex-col md:h-auto gap-4 ">
            {!isMobile ? (
                // Desktop View
                <div className="left w-[80%] flex flex-col gap-4 md:w-full md:gap-4 grid grid-cols-2 lg:gap-4">
        {products.slice(0, 4).map((product, index) => (
            <div
                key={index}
                className="cursor-pointer flex w-full h-full bg-[rgb(226,229,231)] p-4 justify-center items-center lg:flex-col"
                onClick={() => navigate(product?.url)}
            >
                <img src={product.image} alt={product.name} className="w-[200px] h-[200px] lg:w-[150px] lg:h-[150px]" />
                <div className="flex flex-col text-left w-full lg:text-center">
                    <h4>{product.name}</h4>
                    <p className="font-semibold">{product.description}</p>
                    <h4 className="underline">{product.price}</h4>
                </div>
            </div>
        ))}
    </div>
            ) : (
                // Mobile Autocarousel View
                <div className="w-full flex flex-col items-center">
                    <div className="relative w-full overflow-hidden">
                        <div
                            className="flex transition-transform duration-500"
                            style={{ transform: `translateX(-${currentSlide * 100}%)` }}
                        >
                            {products?.map((product, index) => (
                                <div
                                    key={index}
                                    className="min-w-full flex flex-col items-center justify-center bg-[rgb(226,229,231)] p-4"
                                    onClick={() => navigate(product.url)}
                                >
                                    <img src={product.image} alt={product.name} className="w-[150px] h-[150px]" />
                                    <div className="flex flex-col text-center w-full">
                                        <h4>{product.name}</h4>
                                        <p className="font-semibold">{product.description}</p>
                                        <p className="underline">{product.price}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}

            <div className="right w-[20%] flex flex-col items-center justify-evenly border md:w-full md:hidden">
                <h5 className="text-xl font-bold mb-4">Our Services</h5>
                <hr className="w-full border-t-2 border-gray-300 mb-4" />

                {/* Free Shipping */}
                <div className="flex flex-col items-center text-center mb-6 md:mb-4">
                    <FaShippingFast className="text-3xl text-blue-500 mb-2" />
                    <p className="text-lg font-semibold">Free Shipping</p>
                    <p className="text-gray-600 text-sm">Free Shipping On All Orders</p>
                </div>

                {/* 24/7 Support */}
                <div className="flex flex-col items-center text-center mb-6 md:mb-4">
                    <MdSupportAgent className="text-3xl text-blue-500 mb-2" />
                    <p className="text-lg font-semibold">24/7 Support</p>
                    <p className="text-gray-600 text-sm">24/7 Support On All Orders</p>
                </div>

                {/* Secure Payment */}
                <div className="flex flex-col items-center text-center">
                    <RiSecurePaymentFill className="text-3xl text-blue-500 mb-2" />
                    <p className="text-lg font-semibold">Secure Payment</p>
                    <p className="text-gray-600 text-sm">Secure Payment On All Orders</p>
                </div>
            </div>

        </div>
    </>
}

export default Banners