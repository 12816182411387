import React from "react";
import { Helmet } from "react-helmet";

const TermsAndConditions: React.FC = () => {
  return (
    <div className="terms-and-conditions-container w-[95%] mt-[7rem] mx-auto my-8 p-6 bg-white shadow-md rounded-lg">
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <h1 className="text-2xl font-bold mb-4">Terms and Conditions</h1>
      <p className="mb-4">
        Welcome to cayroshop.com. By accessing or using our website, you agree
        to comply with and be bound by the following terms and conditions.
        Please read these terms carefully before using our website or purchasing
        any products.
      </p>

      <h2 className="text-lg font-semibold mt-6">1. Acceptance of Terms</h2>
      <p className="mb-4">
        By accessing, browsing, or using this website or purchasing products,
        you agree to be bound by these Terms and Conditions, including any
        additional guidelines and future modifications. If you do not agree to
        these terms, please do not use our website or purchase our products.
      </p>

      <h2 className="text-lg font-semibold mt-6">2. Product Descriptions</h2>
      <p className="mb-4">
        We strive to provide accurate product descriptions and images. However,
        we do not guarantee that the descriptions or other content on the
        website are accurate, complete, reliable, current, or error-free. If a
        product offered by us is not as described, your sole remedy is to return
        it in unused condition.
      </p>

      <h2 className="text-lg font-semibold mt-6">
        3. Pricing and Availability
      </h2>
      <p className="mb-4">
        All prices are listed in <strong>INR</strong> and are subject to change
        without notice. We make every effort to display accurate pricing, but
        errors may occur. In the event of a pricing error, we reserve the right
        to cancel orders placed at the incorrect price. Product availability is
        subject to change at any time, and we cannot guarantee that an item will
        be in stock.
      </p>

      <h2 className="text-lg font-semibold mt-6">
        4. Order Acceptance and Cancellation
      </h2>
      <p className="mb-4">
        We reserve the right to accept, decline, or cancel your order at any
        time for any reason. After placing an order, you will receive an order
        confirmation email. This email does not signify acceptance of your
        order; it simply confirms that we have received your request. We may
        require additional verification or information before accepting any
        order.
      </p>

      <h2 className="text-lg font-semibold mt-6">5. Payment Terms</h2>
      <p className="mb-4">
        Payment for all products must be made in full at the time of purchase.
        We accept credit cards, debit cards, net banking, UPI, etc. You
        represent and warrant that you have the legal right to use any payment
        method(s) provided by you.
      </p>

      <h2 className="text-lg font-semibold mt-6">6. Shipping and Delivery</h2>
      <p className="mb-4">
        We aim to deliver products within the estimated delivery time; however,
        delays can occur. We are not liable for any delay in delivery beyond our
        control. Shipping costs will be calculated at checkout based on your
        location and the weight of the items purchased. International customers
        are responsible for any customs duties, taxes, or fees imposed by their
        country.
      </p>

      <h2 className="text-lg font-semibold mt-6">
        7. Return and Refund Policy
      </h2>
      <p className="mb-4">
        We offer returns and refunds for products that are defective, damaged,
        or not as described within <strong>7 days</strong> of delivery. To
        initiate a return, please contact our customer service team at{" "}
        <a href="mailto:info@cayroshop.com" className="text-blue-600 underline">
          info@cayroshop.com
        </a>{" "}
        or 9599383349. Items must be returned in their original condition and
        packaging. Refunds will be issued to the original payment method within
        7-10 days after we receive the returned item.
      </p>

      <h2 className="text-lg font-semibold mt-6">8. Warranty Information</h2>
      <p className="mb-4">
        All electronics and home appliances sold by{" "}
        <strong>CAYRO ENTERPRISES</strong> come with a manufacturer’s warranty,
        where applicable. The duration and terms of the warranty vary by product
        and manufacturer. Please refer to the warranty information provided with
        the product for specific details. <strong>CAYRO ENTERPRISES</strong>{" "}
        does not offer additional warranties unless explicitly stated.
      </p>

      <h2 className="text-lg font-semibold mt-6">9. Limitation of Liability</h2>
      <p className="mb-4">
        To the maximum extent permitted by law,{" "}
        <strong>CAYRO ENTERPRISES</strong> shall not be liable for any direct,
        indirect, incidental, special, or consequential damages resulting from
        the use of or inability to use the website or products purchased from
        us, including but not limited to loss of data, profit, or business.
      </p>

      <h2 className="text-lg font-semibold mt-6">10. Intellectual Property</h2>
      <p className="mb-4">
        All content on this website, including but not limited to text,
        graphics, logos, images, and software, is the property of{" "}
        <strong>CAYRO ENTERPRISES</strong> or its content suppliers and is
        protected by intellectual property laws. You may not reproduce,
        distribute, or create derivative works from any content on this website
        without our express written permission.
      </p>

      <h2 className="text-lg font-semibold mt-6">11. Privacy Policy</h2>
      <p className="mb-4">
        Your privacy is important to us. Please review our{" "}
        <a href="/privacy-policy" className="text-blue-600 underline">
          Privacy Policy
        </a>{" "}
        to understand how we collect, use, and protect your personal
        information.
      </p>

      <h2 className="text-lg font-semibold mt-6">12. Governing Law</h2>
      <p className="mb-4">
        These Terms and Conditions shall be governed by and construed in
        accordance with the laws of <strong>India/Haryana</strong>, without
        regard to its conflict of law principles. Any disputes arising out of or
        related to these terms will be subject to the exclusive jurisdiction of
        the courts located in <strong>Gurugram</strong>.
      </p>

      <h2 className="text-lg font-semibold mt-6">
        13. Changes to Terms and Conditions
      </h2>
      <p className="mb-4">
        We reserve the right to modify these Terms and Conditions at any time
        without prior notice. Any changes will be effective immediately upon
        posting on this website. Your continued use of the website following the
        posting of changes constitutes your acceptance of such changes.
      </p>

      <h2 className="text-lg font-semibold mt-6">14. Contact Information</h2>
      <p className="mb-4">
        If you have any questions or concerns about these Terms and Conditions,
        please contact us at:
      </p>
      <ul className="list-disc ml-5">
        <li>
          Email:{" "}
          <a
            href="mailto:info@cayroshop.com"
            className="text-blue-600 underline"
          >
            info@cayroshop.com
          </a>
        </li>
        <li>Phone: 9599383349</li>
        <li>
          Address: Dcg, 104, Dlf Corporate Greens, Sector 74A, Gurugram,
          Haryana-122001
        </li>
      </ul>
    </div>
  );
};

export default TermsAndConditions;
