import React from "react";
import { Link } from "react-router-dom";

const MoreOffers = (props: any) => {
  let { product } = props;
  return (
    <div className="p-4">
      <h2 className="text-xl font-semibold mb-2">Offers</h2>
      <div>
        <h5 className="text-blue-700 font-bold">Bank Offers</h5>
        <div>
          <h1 className="text-md font-semibold mt-2 mb-1">Offer 1</h1>
          <p className="text-base mb-2">
            Flat INR 1250 Instant Discount on HDFC Bank Credit Card 6,9 month
            EMI Trxn. Minimum purchase value ₹15,000
          </p>
          <span className="inline-block">
            <a href="#" className="text-blue-500">
              See details
            </a>
          </span>
          <hr className="mt-4" />
        </div>
        <div>
          <h1 className="text-md font-semibold mt-2 mb-1">Offer 2</h1>
          <p className="text-base mb-2">
            Additional INR 500 Discount on IDFC FIRST Bank Credit Card EMI Trxn.
            Min purchase value ₹15,000
          </p>
          <span className="inline-block">
            <a href="#" className="text-blue-500">
              See details
            </a>
          </span>
          <hr className="mt-4" />
        </div>
        <div>
          <h1 className="text-md font-semibold mt-2 mb-1">Offer 3</h1>
          <p className="text-base mb-2">
            Additional INR 150 Instant Discount on OneCard Credit Card 9 month
            and above EMI Trxn. Min purchase value ₹7,500
          </p>
          <span className="inline-block">
            <a href="#" className="text-blue-500">
              See details
            </a>
          </span>
          <hr className="mt-4" />
        </div>
        <div>
          <h1 className="text-md font-semibold mt-2 mb-1">Offer 4</h1>
          <p className="text-base mb-2">
            Flat INR 1200 Instant Discount on OneCard Credit Card EMI Txn.
            Minimum purchase value ₹15,000
          </p>
          <span className="inline-block">
            <a href="#" className="text-blue-500">
              See details
            </a>
          </span>
          <hr className="mt-4" />
        </div>
        <div>
          <h1 className="text-md font-semibold mt-2 mb-1">Offer 5</h1>
          <p className="text-base mb-2">
            Flat INR 350 Instant Discount on OneCard Credit Card EMI Txn.
            Minimum purchase value ₹7,500
          </p>
          <span className="inline-block">
            <a href="#" className="text-blue-500">
              See details
            </a>
          </span>
          <hr className="mt-4" />
        </div>
        <div>
          <h1 className="text-md font-semibold mt-2 mb-1">Offer 6</h1>
          <p className="text-base mb-2">
            Flat INR 3000 Instant Discount on HDFC Bank Credit Card 6,9 month
            EMI Trxn. Minimum purchase value ₹50,000
          </p>
          <span className="inline-block">
            <a href="#" className="text-blue-500">
              See details
            </a>
          </span>
          <hr className="mt-4" />
        </div>
        <div>
          <h1 className="text-md font-semibold mt-2 mb-1">Offer 7</h1>
          <p className="text-base mb-2">
            Additional flat INR 500 Instant Discount on Amex Credit Card EMI
            Trxn. Minimum purchase value ₹30,000
          </p>
          <span className="inline-block">
            <a href="#" className="text-blue-500">
              See details
            </a>
          </span>
          <hr className="mt-4" />
        </div>
        <div>
          <h1 className="text-md font-semibold mt-2 mb-1">Offer 8</h1>
          <p className="text-base mb-2">
            10% Instant Discount up to INR 1750 on HSBC Credit Card EMI Trnxs.
            Minimum purchase value ₹10,000
          </p>
          <span className="inline-block">
            <a href="#" className="text-blue-500">
              See details
            </a>
          </span>
          <hr className="mt-4" />
        </div>
      </div>
      <div>
        <h5 className="text-blue-700 font-bold">No Cost EMI</h5>
        <div>
          <h1 className="text-md font-semibold mt-2 mb-1">Offer 1</h1>
          <p className="text-base mb-2">
            Upto ₹1,170.98 EMI interest savings on select Credit Cards
          </p>
          <span className="inline-block">
            <a href="#" className="text-blue-500">
              See details
            </a>
          </span>
          <hr className="mt-4" />
        </div>
        <div>
          <h1 className="text-md font-semibold mt-2 mb-1">Offer 2</h1>
          <p className="text-base mb-2">
            Upto ₹1,170.98 EMI interest savings on HDFC Bank Debit Cards
          </p>
          <span className="inline-block">
            <a href="#" className="text-blue-500">
              See details
            </a>
          </span>
          <hr className="mt-4" />
        </div>
        <div>
          <h1 className="text-md font-semibold mt-2 mb-1">Offer 3</h1>
          <p className="text-base mb-2">
            No Cost EMI available on Cayro Pay Later.{" "}
            <Link to="/" className="text-blue">
              Check eligibility here
            </Link>
          </p>
          <span className="inline-block">
            <a href="#" className="text-blue-500">
              See details
            </a>
          </span>
          <hr className="mt-4" />
        </div>
      </div>
      <div>
        <h5 className="text-blue-700 font-bold">Partner Offers</h5>
        <div id="Partner-single-offer" className="block">
          <header className="vsx-offers-desktop-lv__header">
            <h2 className="text-xl font-bold mt-6 mb-4">Partner Offers</h2>
          </header>
          <div className="a-section vsx-offers-desktop-dv__content">
            <h1 className="text-xl mt-4 mb-2">
              Get GST invoice and save up to 28% on business purchases.{" "}
              <a
                href="/gp/b/ref=apay_upi_sopp?node=16179244031"
                className="text-blue-500"
              >
                Sign up for free
              </a>
            </h1>
            <div id="Partner-single-offer-slot" className="a-section mt-4">
              {/* Your promotional content here */}
              <div id="promo_tncPage_A4PDTOFVBP3QG_Se">
                <div id="promo_tnc_container_A4PDTOFVBP3QG_Se" className="mt-4">
                  <div className="font-medium mb-2">Promotion Terms</div>
                  <div className="a-size-base vpc_coupons_for_this_offer_A4PDTOFVBP3QG_Se"></div>
                  <div className="a-size-base a-text-bold promotion_title_A4PDTOFVBP3QG_Se"></div>
                  <div className="a-size-base saving_info_A4PDTOFVBP3QG_Se"></div>
                  <div className="a-size-base expiration_A4PDTOFVBP3QG_Se"></div>
                  <div className="a-size-base see_more_coupons_A4PDTOFVBP3QG_Se"></div>
                  <div className="text-xs">
                    <p>
                      Cayro Business provides purchasing solutions that let
                      registered businesses shop for their company on Cayro and
                      get low prices, bulk discounts, GST invoices, and more.
                      With Cayro Business, you will receive business invoices
                      listing your company/organization name, GST number (if
                      applicable), and Purchase Order (PO) number (if added
                      during ordering). Registration for Cayro Business is free!
                    </p>
                    <p>
                      <strong className="text-xs">
                        Benefits of Cayro Business:
                      </strong>
                    </p>
                    <ol>
                      <li>Saving up to 28% off with GST input credit</li>
                      <li>Saving up to 44% with Bulk Discounts</li>
                      <li>
                        Special reduced business prices on selected products
                      </li>
                      <li>Time-saving business IT features</li>
                    </ol>
                    <p>Ready to register? Follow these steps:</p>
                    <ol>
                      <li>Have your GST number or Business PAN ready.</li>
                      <li>
                        Visit{" "}
                        <Link
                          className="text-blue-800"
                          to="https://www.cayroshop.com"
                        >
                          cayroshop.com
                        </Link>
                        .
                      </li>
                      <li>
                        Enter your business details on the registration page.
                      </li>
                    </ol>
                    <p>Note:</p>
                    <ul>
                      <li>
                        If converting your Mobile-Only Account (MOA) to a
                        business account, you'll need to add an email address.
                      </li>
                      <li>
                        Registration requires a valid GST number or Business
                        PAN.
                      </li>
                    </ul>
                  </div>
                </div>
                <div id="promo" className="hidden_content_A4PDTOFVBP3QG_Se">
                  <div
                    className="a-box a-alert a-alert-warning a-spacing-large a-spacing-top-large"
                    aria-live="polite"
                    aria-atomic="true"
                  >
                    <div className="a-box-inner a-alert-container">
                      <i className="a-icon a-icon-alert"></i>
                      <div className="a-alert-content">
                        <div id=""></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoreOffers;
