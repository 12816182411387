import React, { useEffect } from "react";
import { IoCartOutline } from "react-icons/io5";
import { FaDeleteLeft } from "react-icons/fa6";
import Lottie from "lottie-react";
import wishlistAnimation from "../../loti/wishlist.json";
import { useDispatch, useSelector } from "react-redux";
import {
  loadWishlist,
  removeFromWishlist,
  wishListProducts,
} from "../../slice/WishlistSlice";
import { IsLoggedIn, UserInfo } from "../../slice/UserSlice";
import { Link, useNavigate } from "react-router-dom";
import { addProduct } from "../../slice/CartSlice";
import { AppDispatch } from "../../store/store";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { PostRequest } from "../../api/Request";
import { showErrorToast } from "../../utils/toast";
import { Helmet } from "react-helmet";

const Wishlist = () => {
  const userDetail = useSelector(UserInfo);
  const userIsLoggedIn = useSelector(IsLoggedIn);
  const products = useSelector(wishListProducts) || []; // Ensure products is always an array
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(loadWishlist());
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div className="max-w-[100%] mx-0 my-auto" style={{ marginTop: "7rem" }}>
        <h1
          className="text-gray-900 sm:text-sm sm:mt-[-3rem] sm:mb-[-2.5rem] sm:ml-[-2rem]
      mt-[-1.5rem] text-2xl md:text-lg w-[100%] font-bold sm:mt-6 flex justify-center items-center"
        >
          <Lottie
            animationData={wishlistAnimation}
            className="w-[150px] h-[150px] mr-[-2.9rem]"
          />
          Wishlist
        </h1>

        {/* Conditional rendering for empty or populated wishlist */}
        {Array.isArray(products) && products.length > 0 ? (
          <div className="flex flex-wrap justify-start pb-[2rem] mt-[-3rem]">
            {products.map((product, index) => (
              <div
                key={index}
                className="relative basis-[20%] justify-center flex flex-col items-center sm:basis-[43%] xs:basis-[43%] xss:basis-[42%] m-[1.5rem] sm:m-[0.8rem] bg-white rounded-lg overflow-hidden shadow-md"
                style={{
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                }}
              >
                <Link
                  to={`/product?name=${product.productName}&product_id=${product._id}`}
                >
                  <LazyLoadImage
                    effect="blur"
                    src={product?.images[0]?.url}
                    alt={product.productName}
                    className="w-[200px] h-[180px] sm:h-[150px] object-contain p-2"
                  />
                </Link>

                <div className="p-4 sm:p-2 w-[100%]">
                  <Link
                    to={`/product?name=${product.productName}&product_id=${product._id}`}
                  >
                    <h3 className="text-normal w-[220px] mt-[0.5rem] xss:w-[141px] sm:w-[184px] xs:w-[174px] font-medium sm:text-[0.9rem] text-gray-900 line-clamp-3 w-full">
                      {product.productName}
                    </h3>
                  </Link>
                  <div className="text-lg text-orange-600 sm:text-[0.85rem] font-medium mt-1">
                    ₹ {product.salePrice}
                  </div>
                  <hr />
                  <div className="flex justify-center items-center p-2 rounded-md">
                    <button
                      className="text-black py-1 px-2 sm:px-0 flex items-center rounded-md sm:text-xs"
                      onClick={async () => {
                        if (userIsLoggedIn) {
                          await dispatch(
                            addProduct({
                              ...product,
                              total:
                                Number(product.salePrice) *
                                product.quantityByUser,
                            })
                          );
                          try {
                            if (product) {
                              await PostRequest("/addItemToCart", {
                                productId: product._id,
                                quantity: product.quantityByUser,
                              });
                            } else {
                              showErrorToast(
                                "Product is undefined or not available."
                              );
                            }
                          } catch (error: any) {
                            showErrorToast(
                              error?.response?.data?.message ||
                                "Something went wrong. Please try again."
                            );
                          }
                          dispatch(removeFromWishlist(product._id));
                        } else {
                          navigate("/login");
                        }
                      }}
                    >
                      <IoCartOutline className="mr-[0.5rem] text-xl" />
                      Move to Cart
                    </button>
                  </div>
                </div>
                <button
                  onClick={() => dispatch(removeFromWishlist(product._id))}
                  className="absolute top-2 right-2 text-red-500 sm:text-[1.1rem] p-1 rounded-full text-2xl sm:p-0 transition duration-300 ease-in-out"
                >
                  <FaDeleteLeft />
                </button>
              </div>
            ))}
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center p-2">
            <div className="text-center text-black-700">
              <h1 className="text-2xl sm:text-[14px] sm:leading-[1.35rem] font-bold">
                Hey {userDetail?.name || "Guest"}, it looks like you don’t have
                anything saved.
              </h1>
              <p className="text-s sm:text-sm">
                {!userIsLoggedIn
                  ? "Sign in to save items across all your devices."
                  : "Go and grab your exciting deals"}
              </p>
            </div>
            {!userIsLoggedIn && (
              <Link
                to="/login-by-otp"
                className="mt-4 px-12 py-2 sm:px-[0.9rem] sm:py-[0.5rem] bg-black text-white rounded hover:bg-black mb-2 sm:text-sm sm:px-4"
              >
                Sign In
              </Link>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Wishlist;
